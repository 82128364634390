import type { FC, SVGProps } from "react"

export const Company: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M6.24996 9.16667H3.83329C3.36658 9.16667 3.13323 9.16667 2.95497 9.25749C2.79817 9.33739 2.67068 9.46487 2.59079 9.62167C2.49996 9.79993 2.49996 10.0333 2.49996 10.5V17.5M13.75 9.16667H16.1666C16.6333 9.16667 16.8667 9.16667 17.045 9.25749C17.2018 9.33739 17.3292 9.46487 17.4091 9.62167C17.5 9.79993 17.5 10.0333 17.5 10.5V17.5M13.75 17.5V5.16667C13.75 4.23325 13.75 3.76654 13.5683 3.41002C13.4085 3.09641 13.1535 2.84144 12.8399 2.68166C12.4834 2.5 12.0167 2.5 11.0833 2.5H8.91663C7.9832 2.5 7.51649 2.5 7.15998 2.68166C6.84637 2.84144 6.5914 3.09641 6.43162 3.41002C6.24996 3.76654 6.24996 4.23325 6.24996 5.16667V17.5M18.3333 17.5H1.66663M9.16663 5.83333H10.8333M9.16663 9.16667H10.8333M9.16663 12.5H10.8333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Company
