import {
  FC,
  HTMLAttributes, // useMemo,
} from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import clsx from "clsx"

import useAuth from "hooks/useAuth"

import Image from "components/Image"
import { H3, SerifH5, XSmall } from "styles/Type"
import Link from "next/link"
import { buildNavigationLinks } from "@/lib/sidebarHelper"

// const NEW_PROFILE_MAP = { Artist: "/artist/new", Brand: "/brand/new" }

export const UserMenu: FC<HTMLAttributes<HTMLDivElement>> = ({
  className = "",
  ...props
}) => {
  const { user, logout, clearMasquerade } = useAuth() as any
  const userName = [user?.first_name, user?.last_name].join(" ")

  const userMenuItems = buildNavigationLinks(
    { variant: "userMenu", theme: "default" },
    user
  )

  return (
    <DropdownMenu.Content
      loop
      align="end"
      sideOffset={6}
      alignOffset={-10}
      className={clsx(
        "lg:max-h-[calc(100vh-4rem)] z-50 bg-spring text-offblack flex flex-col overflow-auto lg:rounded-lg lg:w-[20rem] animate-fall-in lg:mr-2.5 lg:mt-2.5 w-screen",
        className
      )}
      {...props}
    >
      <DropdownMenu.Group
        className="bg-spring flex items-center px-4 py-5"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="w-14 h-14 flex-shrink-0 bg-quill rounded-full mr-3.5 overflow-hidden flex justify-center items-center">
          {user?.profile_image ? (
            <Image
              src={user?.profile_image}
              width="56"
              height="56"
              alt={userName}
            />
          ) : (
            <SerifH5 className="text-[#454542] mt-1">
              {userName?.slice(0, 1)}
            </SerifH5>
          )}
        </div>
        <DropdownMenu.Label className="flex-grow">
          <H3 as="h1">{userName}</H3>
          <XSmall className="font-medium text-gunmetal mt-0.5">
            {[user?.job_title, user?.teams?.[0]?.name].join(" - ")}
          </XSmall>
        </DropdownMenu.Label>
      </DropdownMenu.Group>
      {userMenuItems.map((item) => (
        <DropdownMenu.Item
          key={item.href}
          asChild
          onClick={item.label === "Log out" ? logout : () => clearMasquerade()}
        >
          <Link
            href={item.href}
            className={
              item.label === "Log out"
                ? "px-4 pt-5 pb-6 text-bronze hover:text-red focus:text-red focus:outline-none transition duration-150 text-left flex items-center border-t border-black border-opacity-10"
                : "flex items-center px-4 py-3 hover:text-bronze focus:text-bronze focus:outline-none transition duration-150 mt-2 adminItems"
            }
          >
            {item.icon} {item.label}
          </Link>
        </DropdownMenu.Item>
      ))}
    </DropdownMenu.Content>
  )
}

UserMenu.displayName = "UserMenu"

export default UserMenu
